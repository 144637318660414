export default class Constant {
  static BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

  static BASE_STORAGE_URL = process.env.NEXT_PUBLIC_STORAGE_URL;

  static HTTP_OK = 200;

  static HTTP_SERVER_ERROR = 500;

  static HTTP_NOT_FOUND = 404;

  static HTTP_UNAUTHORIZED = 401;

  static HTTP_BAD_REQUEST = 400;

  static HTTP_NOT_ACCEPTABLE = 406;

  static HTTP_TOO_MANY_REQUEST = 429;

  static HTTP_FORBIDDEN = 403;

  static HTTP_UNPROCESSABLE_CONTENT = 422;

  static FILTER_SEARCH_AUTO_TIMEOUT = 500;
}
