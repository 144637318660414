import axios from "axios";
import {
  BaseResponseModel,
  BaseMessageModel,
} from "../models/baseResponseModel";
import { LawFileModel } from "../models/lawFileModel";

export type PostData = {
  identifier: string;
};

export default class FileRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async fetchDownload(
    id_legislation: string,
    id_doc: string,
    data: PostData,
  ): Promise<any> {
    try {
      const response = await axios.post(
        `${this.baseUrl}/laws/detail/${id_legislation}/download/${id_doc}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const responseData:
        | BaseResponseModel<Record<string, unknown>>
        | BaseMessageModel = response.data;

      return responseData;
    } catch (error) {
      throw error;
    }
  }
}
