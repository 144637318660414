// components/LoadingDialog.tsx
import React, { useEffect } from "react";

const LoadingDialog: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="rounded-md bg-white p-6 text-center shadow-lg">
        {/* <h3 className="mb-4 text-lg font-semibold">Loading...</h3> */}
        {/* Spinner */}
        <div className="h-16 w-16 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-500"></div>
      </div>
    </div>
  );
};

export default LoadingDialog;
