import Api from "@/src/apis/api";
import {
  BaseResponseModel,
  BasePagingResponseModel,
} from "../models/baseResponseModel";
import { LawModel } from "../models/lawModel";

interface DocumentsResponse {
  code: number;
  status: string;
  message: string;
  page: number;
  limit: number;
  total: number;
  last_page: number;
  data: LawModel[];
}

interface DetailResponse {
  code: number;
  status: string;
  message: string;
  data: LawModel;
}

export default class ProductOfLawRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
    // console.log("API Base URL:", this.baseUrl);
  }

  productList: Array<LawModel> = [];
  product: LawModel | null = null;

  // async getAll()
  async fetchAll({
    page = 1,
    limit = 12,
    type,
    sort = "",
  }: {
    page: number;
    limit: number;
    type: number;
    sort?: string;
  }): Promise<{ data: LawModel[]; last_page: number }> {
    const url = new URL(`${this.baseUrl}/laws`);
    const params = new URLSearchParams();
    if (page) params.append("page", page.toString());
    if (limit) params.append("limit", limit.toString());
    if (type) params.append("type", type.toString());
    if (sort) params.append("sort", sort.toString());

    url.search = params.toString();
    // console.log(url);

    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            return reject(new Error("Failed to fetch data"));
          }
          return response.json();
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  // get product of law by popular
  async fetchPopular(page: number = 1, limit: number = 2): Promise<LawModel[]> {
    try {
      const response = await fetch(
        `${this.baseUrl}/laws/popular?page=${page}&limit=${limit}`,
      );

      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }

      const data: DocumentsResponse = await response.json();
      return data.data; // Return only the documents array
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  }

  // get product of law by lastest submit
  async fetchLatest(page: number = 1, limit: number = 2): Promise<LawModel[]> {
    try {
      const response = await fetch(
        `${this.baseUrl}/laws/newest?page=${page}&limit=${limit}`,
      );

      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }

      const data: DocumentsResponse = await response.json();
      return data.data; // Return only the documents array
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  }

  // async getDetail() {}
  async fetchDetail(id: string): Promise<LawModel> {
    try {
      const response = await fetch(`${this.baseUrl}/laws/detail/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }
      const data: DetailResponse = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching document detail:", error);
      throw error;
    }
  }

  // async getSearch
  async fetchSearch({
    term = "",
    page = 1,
    limit = 12,
    type = "",
    year = "",
    category = "",
    field = "",
  }: {
    page: number;
    limit: number;
    term?: string;
    type?: string;
    year?: string;
    category?: string;
    field?: string;
  }): Promise<{ data: LawModel[]; last_page: number; total: number }> {
    return new Promise((resolve, reject) => {
      const url = new URL(`${this.baseUrl}/laws/search`);

      // Create a URLSearchParams object to hold the query parameters
      const params = new URLSearchParams();
      if (page) params.append("page", page.toString());
      if (limit) params.append("limit", limit.toString());
      if (term) params.append("q", term);
      if (type) params.append("type", type);
      if (year) params.append("year", year);
      if (category) params.append("category", category);
      if (field) params.append("field", field);

      url.search = params.toString();

      // console.log(url);

      fetch(url, {
        cache: "no-cache",
      })
        .then((response) => {
          if (!response.ok) {
            return reject(new Error("Failed to fetch data"));
          }
          return response.json();
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
