interface YearObject {
  value: string;
  label: string;
}

const GenerateYears = (startYear: number): YearObject[] => {
  const currentYear = new Date().getFullYear();
  const years: YearObject[] = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  return years;
};

export default GenerateYears;
