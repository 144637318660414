import React from "react";

interface SkeletonProps {
  number: number;
  columns?: {
    base?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const SkeletonDoc: React.FC<SkeletonProps> = ({
  number,
  columns = { base: 1, md: 1, lg: 3, xl: 3 },
}) => {
  const baseClass = `grid-cols-${columns.base}`;
  const mdClass = `md:grid-cols-${columns.md}`;
  const lgClass = `lg:grid-cols-${columns.lg}`;
  const xlClass = `xl:grid-cols-${columns.xl}`;
  return (
    <div className={`grid gap-4 ${baseClass} ${mdClass} ${lgClass} ${xlClass}`}>
      {[...Array(number)].map((_, index) => (
        <div key={index}>
          <div className="flex w-full rounded-md border bg-white font-sans shadow-xl">
            <div className="flex-auto p-4">
              <div className="flex w-11/12 flex-col gap-2">
                <span className="w-12/12 h-2 animate-pulse rounded-full bg-gray-300"></span>
                <span className="h-2 w-10/12 animate-pulse rounded-full bg-gray-300"></span>
              </div>
              <div className="center flex flex-row items-center pt-8">
                <div className="h-[28px] w-[28px] animate-pulse rounded-md bg-gray-300"></div>
                <span className="ml-2 h-2 w-8/12 animate-pulse rounded-full bg-gray-300"></span>
              </div>
              <div className="center flex flex-row items-center pt-2">
                <div className="h-[28px] w-[28px] animate-pulse rounded-md bg-gray-300"></div>
                <span className="ml-2 h-2 w-4/12 animate-pulse rounded-full bg-gray-300"></span>
              </div>
              <div className="mt-3 flex items-center justify-center rounded-lg bg-gray-100 p-2">
                <div className="flex w-full max-w-md gap-2">
                  <button className="h-8 w-1/2 animate-pulse rounded-lg border-2 bg-gray-300 px-4 py-2 text-center"></button>
                  <button className="h-8 w-1/2 animate-pulse rounded-lg border-2 bg-gray-300 px-4 py-2 text-center text-[#191970]"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonDoc;
